import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { Notice } from 'hudl-rn-uniform-ui';


const snippet = require('raw-loader!../../../../../data/snippets/notice.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Notice: Notice }}
        code={snippet}
        platform="react-native"
        gitHubLink="notifications/notice"
      />
      <Section title="Props">
        <PropList>
          <PropListItem name="type" types={['oneOf', 'string']}>
            <Text>Determines the type of notice.</Text>
            <List type="unordered">
              <li>
                <code>information</code>
              </li>
              <li>
                <code>confirmation</code>
              </li>
              <li>
                <code>warning</code>
              </li>
              <li>
                <code>critical</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="title" types={['oneOf', 'string']}>
            <Text>The title text at the top of the notice.</Text>
          </PropListItem>

          <PropListItem name="message" types={['oneOf', 'string', 'element']}>
            <Text>
              The text that appears in the notice. To include a{' '}
              <Link href="/components/link" isDesignCodeLink>
                link
              </Link>
              , you'll need to wrap your text with the{' '}
              <Link href="/components/type/text" isDesignCodeLink>
                text
              </Link>{' '}
              component.
            </Text>
          </PropListItem>

          <PropListItem name="action" types={['shape']}>
            <Text>A subtle button that shows beneath the notice message.</Text>
            <List type="unordered">
              <li>
                <code>text</code> - the text to display in the button
              </li>
              <li>
                <code>onActionPress</code> - func to pass in to handle button press
              </li>
            </List>
          </PropListItem>

          <PropListItem name="testID" types={['string']}>
            <Text>
              A unique string to control this notice in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="accessibilityLabel" types={['string']}>
            <Text>
              Overrides the text that's read by the screen reader when the user interacts with the notice.
            </Text>
          </PropListItem>

          <PropListItem name="onDismiss" types={['func']}>
            <Text>Responds to the notice being dismissed.</Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
