import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, Notice } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../../data/snippets/notice.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Notice: Notice }}
        code={snippet}
        platform="react"
        gitHubLink="notifications/notice"
      />
      <Section title="Props">
        <PropList>
          <PropListItem name="type" types={['oneOf', 'string']}>
            <Text>Determines the type of notice.</Text>
            <List type="unordered">
              <li>
                <code>information</code>
              </li>
              <li>
                <code>confirmation</code>
              </li>
              <li>
                <code>warning</code>
              </li>
              <li>
                <code>critical</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="style" types={['oneOf', 'string']}>
            <Text>Determines the notice style.</Text>
            <List type="unordered">
              <li>
                <code>standard</code> (default)
              </li>
              <li>
                <code>minimal</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="title" types={['oneOf', 'string']}>
            <Text>The title text at the top of the notice.</Text>
          </PropListItem>

          <PropListItem name="message" types={['oneOf', 'string', 'element']}>
            <Text>
              The text that appears in the notice. To include a{' '}
              <Link href="/components/link" isDesignCodeLink>
                link
              </Link>
              , you'll need to wrap your text with the{' '}
              <Link href="/components/type/text" isDesignCodeLink>
                text
              </Link>{' '}
              component.
            </Text>
          </PropListItem>

          <PropListItem name="action" types={['shape']}>
            <Text>A subtle button that shows beneath the notice message.</Text>
            <List type="unordered">
              <li>
                <code>text</code> - the text to display in the button
              </li>
              <li>
                <code>handler</code> - func to pass in to handle button click
              </li>
              <li>
                <code>href</code> - links to another page instead of performing
                an action
              </li>
              <li>
                <code>target</code> - determines how the link passed in the href
                prop will open
              </li>
              <li>
                <code>qaId</code> - the data-qa-id for the button
              </li>
            </List>
          </PropListItem>

          <PropListItem name="collapseMargin" types={['oneOf', 'string']}>
            <Text>
              Removes the top, bottom, or vertical margin of the notice.
            </Text>
            <List type="unordered">
              <li>
                <code>top</code>
              </li>
              <li>
                <code>bottom</code>
              </li>
              <li>
                <code>vertical</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string that appears as a data attribute in the rendered
              code, serving as a hook for automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="onDismiss" types={['func']}>
            <Text>Responds to the notice being dismissed.</Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
